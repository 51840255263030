import { createApp } from 'vue'
import App from './App.vue'
import store from './store'

import globalSCSS from "./theme/global.scss"
import globalFunctions from "./functions.js"

createApp(App)
    .use(store)
    .use(globalSCSS)
    .use(globalFunctions)
    .mount('#app')
