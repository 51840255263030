export default {
    install(Vue) {
        Vue.mixin({
            data() {
                return {
                    minBalloonsCount: 60,
                }
            },
            computed: {
                formatColor() {
                    return color => color?.charAt(0) === '#' ? color : '#' + color
                },
            }
        })
    }
}
