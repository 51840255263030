<template>
    <div class="component-max-count-handler">
        <div class="inner-wrapper">
            <div
                class="count-button decrement"
                :class="{
                    'disable-element': colorDataCopy?.maxCount <= minBalloonsCount
                }"
                @click="updateCount('decrement')"
            >
                <img
                    class="count-button__icon"
                    :src="minus"
                    alt="minus"
                />
            </div>

            <input
                :id="inputId"
                class="max-count-input"
                type="number"
                :value="colorDataMaxCount"
                @blur="modifyValue"
            />

            <div
                class="count-button increment"
                @click="updateCount('increment')"
            >
                <img
                    class="count-button__icon"
                    :src="plus"
                    alt="minus"
                />
            </div>
        </div>
    </div>
</template>

<script>
import minus from "../assets/icons/minus.svg"
import plus from "../assets/icons/plus.svg"

export default {
    name: "MaxCountHandler",
    props: {
        colorData: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {
            minus, plus,
        }
    },
    methods: {
        /**
         * Increment or decrement max balloons count
         * @param {string} type: increment / decrement
         */
        updateCount(type) {
            const factorValue = 10
            if (type === 'increment') {
                this.colorDataCopy.maxCount += factorValue
            } else {
                this.colorDataCopy.maxCount -= factorValue
            }

            // check input value with current color count, if different, set them
            this.$nextTick(() => {
                const input = document.querySelector(`#${this.inputId}`)

                const currentInputValue = Number(input.value)
                if (currentInputValue < this.colorDataCopy.count) {
                    this.colorDataCopy.count = currentInputValue
                }
            })
        },
        /**
         * Update value after input lost focus
         * @param ev - event from input
         */
        modifyValue(ev) {
            const count = Number(ev.target.value)
            // console.log('count', count)
            const input = document.querySelector(`#${this.inputId}`)

            if (count <= this.minBalloonsCount) {
                const defaultValue = this.minBalloonsCount
                // console.log('defaultValue', defaultValue)

                this.colorDataCopy.count = defaultValue // reset selected count
                this.colorDataCopy.maxCount = defaultValue // set max count for range slider
                input.value = defaultValue // set value into input
            } else {
                const roundedCount = Math.ceil(count / 10) * 10
                // console.log('roundedCount', roundedCount)

                this.colorDataCopy.count = roundedCount
                this.colorDataCopy.maxCount = roundedCount // set max count for range slider
                input.value = roundedCount // set value into input
            }
        }
    },
    computed: {
        colorDataCopy: {
            get() {
                return this.colorData
            },
            set(val) {
                return this.$emit('update:color-data', val)
            }
        },
        colorDataMaxCount() {
            return JSON.parse(JSON.stringify(this.colorDataCopy?.maxCount))
        },
        inputId() {
            return 'input-max-value--' + this.colorData.id
        }
    }
}
</script>

<style lang="scss" scoped>
.component-max-count-handler::v-deep(.inner-wrapper) {
    display: flex;
    gap: 3px;
    margin-left: 15px;
    min-height: 25px;

    border: 1px solid $brown-light;

    .max-count-input {
        width: 40px;

        border: 0;
        font-size: 15px;
        text-align: center;
        background: transparent;

        &:focus-visible {
            outline: none;
        }

        &[type=number]::-webkit-inner-spin-button,
        &[type=number]::-webkit-outer-spin-button {
            display: none;
            margin: 0;
            -webkit-appearance: none;
        }
    }

    .count-button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 7px;
        min-height: 100%;

        cursor: pointer;
        font-weight: 600;
        background: $brown-light;

        &__icon {
            width: 13px;
        }

        .increment {
            //
        }

        .decrement {
            //
        }
    }
}
</style>