<template>
    <div id="app">
        <div class="container">

            <!-- SECTION - HEADER -->
            <section class="header">
                <h1 class="title title--1">{{ title }}</h1>
                <div class="subtitle">{{ subtitle }}</div>
            </section>

            <StabilityAI />

            <!-- SECTION - COLOR SELECTION -->
            <section class="color-selection">
                <div class="selected-colors">
                    <div
                        class="selected-color"
                        v-for="(colorData, index) in selectedColors" :key="index"
                    >
                        <SelectedColor
                            v-model:color-data="selectedColors[index]"
                        />
                    </div>
                </div>

                <p
                    class="error-text"
                    v-if="validateCountMessage"
                    v-html="validateCountMessage"
                ></p>

                <ColorAdd
                    v-model:color-list="colorList"
                />

                <div>Celkovy pocet balonov: {{ totalBalloonsCount }}</div>
                <div>Celkova cena girlandy: {{ garlandPrice }}</div>
            </section>

<!--            <section style="margin-top: 70px">-->
<!--                <div-->
<!--                    v-for="(item, index) in selectedColors" :key="index"-->
<!--                    style="margin-bottom: 10px">-->
<!--                    {{ item }}-->
<!--                </div>-->
<!--            </section>-->

            <button class="primary">save</button>
        </div>
    </div>
</template>

<script>
import SelectedColor from "./components/SelectedColor.vue";
import ColorAdd from "./components/ColorAdd.vue";
import StabilityAI from "./components/StabilityAI.vue";

export default {
    name: 'App',
    components: {
        StabilityAI,
        SelectedColor,
        ColorAdd
    },
    data() {
        return {
            title: 'Girlanda na mieru',
            subtitle: 'Caramels sesame snaps dragée topping muffin tart cupcake jujubes danish. Brownie gummies cheesecake muffin cheesecake donut tart.',

            colorList: [
                { id: 1, color: '#CBA223', selected: false, count: 60, maxCount: 100, },
                { id: 2, color: '#FF0000', selected: false, count: 60, maxCount: 100, },
                { id: 4, color: '#5CC823', selected: false, count: 60, maxCount: 100, },
                { id: 5, color: '#0000FF', selected: false, count: 60, maxCount: 100, },
                { id: 5, color: '#000000', selected: false, count: 60, maxCount: 100, },
                { id: 5, color: '#FFFFFF', selected: false, count: 60, maxCount: 100, },
            ],
        }
    },
    computed: {
        selectedColors() {
            return this.colorList.filter(color => color.selected)
        },
        validateCountMessage() {
            if (this.selectedColors?.length) {
                const currentCount = this.selectedColors.map(color => color.count).reduce((partialSum, a) => partialSum + a, 0)
                const differenceCount = this.minBalloonsCount - currentCount

                if (currentCount < this.minBalloonsCount) {
                    return `Minimalny pocet balonov pre girladu je <strong>60</strong> kusov. Chyba vam este <strong>${differenceCount}</strong> kusov`
                } else {
                    return false
                }
            } else {
                return false
            }
        },
        /**
         * Total number of selected balloons
         */
        totalBalloonsCount() {
            const allCounts = this.selectedColors?.map(color => color.count) // [10, 50, 20]
            return allCounts?.reduce((acc, val) => acc + val, 0) // return 80 | 0
        },
        garlandPrice() {
            const totalPrice = this.totalBalloonsCount * 0.26
            return totalPrice.toFixed(2)
        }
    }
}
</script>

<style src="@vueform/slider/themes/default.css"></style>
<style lang="scss">
.header {
    .subtitle {
        font-size: 14px;
        margin-bottom: 20px;
    }
}

.color-selection {
    .selected-colors {
        .selected-color {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 15px;
        }
    }
}

</style>
