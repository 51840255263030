<template>
    <div class="component-selected-color">
        <div class="inner-wrapper selected-color-wrapper">
            <ColorItem
                :color="formatColor(colorDataCopy.color)"
                :can-delete-icon="true"
                :show-slider="true"
                @click="deleteColorItem(colorDataCopy)"
            />

            <Slider
                class="range-slider"
                :step="rangeSliderSettings.step"
                :min="rangeSliderSettings.max"
                :showTooltip="rangeSliderSettings.showTooltip"
                :max="colorDataCopy.maxCount"
                v-model="colorDataCopy.count"
            />

            <MaxCountHandler
                v-model:color-data="colorDataCopy"
            />
        </div>
    </div>
</template>

<script>
import ColorItem from "./ColorItem.vue";
import Slider from '@vueform/slider'
import MaxCountHandler from "./MaxCountHandler.vue";

export default {
    name: "SelectedColor",
    components: {
        ColorItem,
        Slider,
        MaxCountHandler,
    },
    props: {
        colorData: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {
            rangeSliderSettings: {
                max: 10,
                step: 10,
                showTooltip: 'always', // always|focus|drag
            },
        }
    },
    methods: {
        deleteColorItem(colorData) {
            colorData.selected = !colorData.selected
        }
    },
    computed: {
        colorDataCopy: {
            get() {
                return this.colorData
            },
            set(val) {
                this.$emit('update:colorData', val)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.component-selected-color {
    width: 100%;
}

.component-selected-color::v-deep(.selected-color-wrapper) {
    display: flex;
    gap: 10px;
    align-items: center;

    // component slider
    .range-slider {
        width: 100%;

        // full slide line
        .slider-connects {
            background: $brown-light;

            // selected slide line
            .slider-connect {
                background: $brown;
                border: 1px solid $brown;
            }
        }

        .slider-origin {
            // slide button - sliding ball
            .slider-handle {
                //background: $brown;

                &:focus {
                    box-shadow: $brown .5px .5px 2px 1px ;
                    outline: none;
                }
            }

            // slide button - tooltip
            .slider-tooltip {
                padding: 0 5px;
                bottom: 15px;
                height: 20px;

                font-size: 12px;
                font-weight: 600;
                background: $brown-light;
                color: $brown;
                border-color: $brown-light;

                &:before {
                    border-top-color: $brown-light;
                }
            }
        }
    }
}
</style>