<template>
    <div class="stability-ai">
        <div class="inner-wrapper">

            <div>
                ak dojde kredit, treba zmenit token: zostavajuci kredit: <strong>{{ credit.toFixed(2) }}</strong>
                <textarea v-model="apiKey" style="width:100%"></textarea>
            </div>

            <div>
                <div>vybraty model: <strong>{{ selectedModel.title }}</strong></div>
                <div>PRICE za image: <strong>{{ selectedModel.price }}</strong></div>

                <div>vyber modelu:</div>
                <button @click="selectedModel = models[0]" style="margin-left: 20px">{{ models[0].title }} - {{ models[0].price }} DRAHSI</button>
            </div>

            <div style="margin: 10px 0; border-top: 1px solid black">
                <div>Vzorkovanie: <strong>cislo medzi 10 - 150</strong></div>
                <div>cim vyssie cislo, tym by mala byt vyssia presnost vygenerovane obrazka, ale zvysuje sa cas, dlzka generovania</div>
                <input v-model="steps">
            </div>

            <div style="margin: 10px 0; border-top: 1px solid black">
                <div>sampler --- typ vzorkovania, zatial nemenit, hladam najlepsi</div>
                <div>DDIM DDPM K_DPMPP_2M K_DPMPP_2S_ANCESTRAL K_DPM_2 K_DPM_2_ANCESTRAL K_EULER K_EULER_ANCESTRAL K_HEUN K_LMS</div>
                <input v-model.trim="sampler">
            </div>

            <div style="margin: 10px 0; border-top: 1px solid black">
                <div>style_preset --- <strong>enhance</strong> zatial najlepsi</div>
                <div>3d-model analog-film anime cinematic comic-book digital-art enhance fantasy-art isometric line-art low-poly modeling-compound neon-punk origami photographic pixel-art tile-texture</div>
                <input v-model.trim="style_preset">
            </div>

            <div style="margin: 10px 0; border-top: 1px solid black;background: pink; padding: 5px">
                text pre generovanie obrazka
                <textarea v-model="text" style="width: 100%; min-width: 50px"></textarea>
            </div>

            <img v-if="image" style="width:100%; max-width: 300px" :src="image" alt="test" />

            <div v-if="loaders.createImage">Nacitava...</div>
            <button v-else style="width: 100%;margin-top: 50px" @click="getImage">get image</button>
        </div>
    </div>
</template>

<script>
import config from "../config";
import axios from "axios/index";

export default {
    name: "StabilityAI",
    data() {
        return {
            api: 'https://api.stability.ai',
            apiKey: 'sk-dLqKZbcoSruIoJ4yS1E4LiLUjZ390zMoMCFUCmJhKXx35Dir',

            models: [
                { title: 'SDXL v1.0', price: '1,8', id: 'stable-diffusion-xl-1024-v1-0' },
                // { title: 'SD v2.1', price: '0,27', id: 'stable-diffusion-512-v2-1' },
                // { title: 'SDXL beta v2.2.2', price: '1,8', id: 'stable-diffusion-xl-beta-v2-2-2' },
            ],
            selectedModel: { title: 'SDXL v1.0', price: '1,8', id: 'stable-diffusion-xl-1024-v1-0' },

            text: 'A balloon garland in the sunny garden composed of ten red balloons and twenty green balloons, in the shape of a cloud.',
            image: '',
            credit: 0,

            steps: 50,
            style_preset: 'enhance',
            sampler: '',

            loaders: {
                createImage: false,
            }
        }
    },
    mounted() {
        this.testGet()
    },
    methods: {
        testGet() {
            const apiUser = '/v1/user/account'
            const apiEngines = '/v1/engines/list'

            config.HEADERS.headers['Authorization'] = `Bearer ` + this.apiKey
            axios.get(this.api + apiUser, config.HEADERS).then(res => {
                console.log('user', res)
            })

            axios.get(this.api + apiEngines, config.HEADERS).then(res => {
                console.log('engines', res)
            })

            axios.get(this.api + '/v1/user/balance', config.HEADERS).then(res => {
                console.log('balance', res.data.credits, res)
                this.credit = res.data.credits
            })
        },
        getImage() {
            this.loaders.createImage = true

            let postData = {}
            postData['text_prompts'] = [
                {
                    'text': this.text
                    // "weight": 1
                },
            ]
            postData['cfg_scale'] = 7
            postData['style_preset'] = this.style_preset
            postData['steps'] = Number(this.steps)
            if (this.sampler) postData['sampler'] = this.sampler

            // const testId = 'stable-diffusion-512-v2-1' // 0,27
            // const testId = 'stable-diffusion-xl-1024-v1-0' // 1,8
            const createApi = this.api + '/v1/generation/' + this.selectedModel.id + '/text-to-image'

            config.HEADERS.headers['Authorization'] = `Bearer ` + this.apiKey
            axios.post(createApi, postData, config.HEADERS).then(res => {
                console.log('getImage', res)
                this.image = 'data:image/jpeg;base64,' + res.data.artifacts[0]?.base64
            }).finally(() => {
                this.loaders.createImage = false
                this.getBalance()
            })
        },
        getBalance() {
            config.HEADERS.headers['Authorization'] = `Bearer ` + this.apiKey
            axios.get(this.api + '/v1/user/balance', config.HEADERS).then(res => {
                console.log('balance', res.data.credits, res)

                this.credit = res.data.credits
            })
        }
    },
    computed: {
        createSentence() {
            return 'A balloon garland in the shape of a cloud in a garden composed of randomly distributed 10 velvet green balloons and 10 gold balloons and 10 red balloons'
        }
    }
}
</script>

<style lang="scss" scoped>
.stability-ai::v-deep(.inner-wrapper) {
    //
}
</style>