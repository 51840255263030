<template>
    <div class="component-color-add">
        <div class="inner-wrapper">
            <img
                class="color-add-btn"
                :src="addButtonIcon"
                alt="add icon"
                @click="showColorList"
            />

            <div
                class="colors-to-choose-container"
                :class="{ 'is-visible': isColorListVisible }"
            >
                <div class="colors-to-choose-wrapper">
                    <ColorItem
                        v-for="(colorData, index) in colorListCopy" :key="index"
                        :color="colorData.color"
                        :can-selected-icon="true"
                        :is-selected="colorData.selected"
                        @click="selectColor(colorData)"
                    />
                </div>

            </div>

        </div>
    </div>
</template>

<script>
import addOutline from "../assets/icons/addOutline.svg"
import closeOutline from "../assets/icons/closeOutline.svg"
import ColorItem from "./ColorItem.vue"

export default {
    name: "ColorAdd",
    components: {
        ColorItem
    },
    props: {
        colorList: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data() {
        return {
            addOutline, closeOutline,

            isColorListVisible: false,
        }
    },
    methods: {
        showColorList() {
            this.isColorListVisible = !this.isColorListVisible
        },
        selectColor(colorData) {
            this.isColorListVisible = false // close color list popup
            colorData.selected = !colorData.selected
        },
    },
    computed: {
        colorListCopy: {
            get() {
                return this.colorList
            },
            set(val) {
                this.$emit('update:color-list', val)
            }
        },
        addButtonIcon() {
            return this.isColorListVisible ? this.closeOutline : this.addOutline
        }
    }
}
</script>

<style lang="scss" scoped>
.component-color-add::v-deep(.inner-wrapper) {
    position: relative;

    .color-add-btn {
        width: 100%;
        max-width: 35px;
        cursor: pointer;
    }

    .colors-to-choose-container {
        position: absolute;
        width: 100%;
        left: 0;
        top: 35px;

        opacity: 0;
        max-height: 0;
        overflow: hidden;
        background: $brown-light;
        transition: all .15s ease-out;

        user-select: none;
        pointer-events: none;

        .colors-to-choose-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 10px;
            margin: 10px;
        }

        &.is-visible {
            opacity: 1;
            max-height: unset;
            transition: all .15s ease-in;

            user-select: inherit;
            pointer-events: inherit;
        }
    }
}
</style>