<template>
    <div class="component-color-item">
        <div class="inner-wrapper color-item-wrapper">
            <div class="color-preview"></div>

            <img
                class="selected-icon"
                v-if="canSelectedIcon && isSelected"
                :src="selected"
                alt="selected"
            />

            <img
                class="delete-icon"
                v-if="canDeleteIcon"
                :src="close"
                alt="delete"
            />
        </div>
    </div>
</template>

<script>
import close from "../assets/icons/close.svg"
import selected from "../assets/icons/selected.svg"

export default {
    name: "ColorItem",
    props: {
        /**
         * Color of item, in hexadecimal notation
         * @example '#CBA223'
         */
        color: {
            type: String,
            default: ''
        },
        /**
         * Determines whether the DELETE icon should be displayed
         */
        canDeleteIcon: {
            type: Boolean,
            default: false
        },
        /**
         * Determines whether the SELECTED icon should be displayed
         */
        canSelectedIcon: {
            type: Boolean,
            default: false
        },
        /**
         * Determines whether color item is selected
         */
        isSelected: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            // icons
            close, selected,
        }
    },
}
</script>

<style lang="scss" scoped>
.component-color-item::v-deep(.color-item-wrapper) {
    position: relative;
    width: 30px;
    height: 30px;
    aspect-ratio: 1;
    border: 0;
    border-radius: 50%;

    .color-preview,
    .delete-icon,
    .selected-icon {
        cursor: pointer;
    }

    .color-preview {
        width: 100%;
        height: 100%;
        border-radius: inherit;
        background: v-bind(color);
        transition: all .3s;
        outline: 2px solid $brown-light;
    }

    .delete-icon,
    .selected-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        transition: all .3s;
    }

    .delete-icon {
        width: 20px;
        opacity: 0;
    }

    .selected-icon {
        width: 16px;
    }

    &:hover {
        .color-preview {
            opacity: 0.5;
        }

        .delete-icon {
            opacity: 1;
        }
    }
}
</style>