export default {
    // BASE_URL: "",

    // API_URL: "",
    // API_URL_V1: "",

    HEADERS: {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }
    },
    FD_HEADERS: {
        headers: {
            "Accept": "application/json",
            "Content-Type": "multipart/form-data"
        }
    },
}